<template>
    <div style="padding:30px;">
        <el-carousel :interval="4000" type="card" height="250px">
            <el-carousel-item v-for="item in context" :key="item.id">
                <el-image :src="item.url" class="showimg"></el-image>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name:'CarCarouselBoard',
    props:['context'],
    data(){
        return{
            
        }
    }
}
</script>

<style scoped>
.showimg{
    height:300px;
    width: 100%
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}
  
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>