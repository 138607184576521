<template>
    <div class="container">
        <div v-for="item in show" :key="item.id">
            <el-card :body-style="{ padding: '0px'}" class="showCard" style="padding-bottom:10px;">
                <div style="height:162px;width:100%;">
                    <img :src="item.src" style="width:100%;"/>
                </div>
                <div style="padding: 10px;">
                    <span style="font-size:20px;">
                        <router-link :to="{
                            path:item.to,
                            query:{
                                type:item.id,
                            }
                        }"
                        class="link">
                            {{item.title}}
                        </router-link></span>
                    <el-divider></el-divider>
                    <div style="word-wrap: break-word;text-align:left;">{{item.content}}</div>
                    <div class="bottom clearfix"></div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name:'StretchDisplayBoard',
    props:['show'],
    data() {
        return {

        };
    }
}
</script>

<style scoped>
    .container{
        display:flex;
        justify-content:
        space-around;
        height:305px;
    }
    
    .bottom {
    margin-top: 13px;
    line-height: 12px;
    }
    
    .clearfix:before, .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }
    .el-divider--horizontal {
        margin: 5px 0;
    }
    .showCard {
        width:250px;
        height:200px;
        align-items: center;
    }
    .showCard:hover {
        animation-name: extend;
        animation-duration: 1s;
        animation-fill-mode: both;
    }
    @keyframes extend {
        from {width:250px;height:200px;}
        to {width:300px;height: 300px;}
    }
    .link {
        color:#000;
    }
    .link:hover{
        color:rgb(82,191,234);
    }
</style>