<template>
    <div class='container'>
        <div class='box' v-for="item in content" :key="item.id">
            <figure class="imghvr-push-left">
                <img class='img' :src='item.url'/>
                <figcaption style="font-size:16px;text-align: left;">
                {{item.text}}
                </figcaption>
            </figure>
            <!-- <div class='text'>ceshiwenzi</div> -->
        </div>
    </div>
</template>

<script>
export default {
    name:'ProductDisplay',
    props:['content'],
    data(){
        return{
            
        }
    }
}
</script>

<style scoped lang='less'>
    .img{
        width:100%;
    }
    .imghvr-push-left:hover+.text{
        display: none;
    }
    .text{
        position:absolute;
        /* z-indent:2; */
        left:30px;
        top:20px;
        color:#fff;
    }
    .container{
        display:flex;

    }
    .box{
        position:relative;
        // margin:15px;
        padding: 20px;
        width: 100%;
        /* color:#fff; */
        color:#000;
        font-size: 24px;
        background-size:cover !important;
    }
</style>