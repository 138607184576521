<template>
  <div id="app">
  <el-container>
    <el-header>
      <el-menu
      :default-active="$route.path"
      router
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#fff"
      text-color="#333333"
      active-text-color="#00aae6">
        <!-- 第一栏 -->
        <el-menu-item index="/">
            <el-image
            class="menu-img"
            :src="logo"
            fit="contain">
            </el-image>
        </el-menu-item>
        <!-- 第二栏 -->
        <el-menu-item index="/home" class="font">首页
        </el-menu-item>
        <!-- 第三栏 -->
        <el-menu-item index="/onlinecar" class="font">网约车平台
        </el-menu-item>
        <!-- 第四栏 -->
        <el-menu-item index="/govaffairs" class="font">政务监管平台</el-menu-item>
        <!-- 第五栏 -->
        <el-menu-item index="/monitor" class="font">动态监控平台</el-menu-item>
        <!-- <el-submenu index="5">
          <template slot="title"><font class="font">动态监控平台</font></template>
          <el-menu-item index="5-1" class="menu-item">选项1</el-menu-item>
          <el-menu-item index="5-2" class="menu-item">选项2</el-menu-item>
          <el-menu-item index="5-3" class="menu-item">选项3</el-menu-item>
          <el-submenu index="5-4">
            <template slot="title">选项4</template>
            <el-menu-item index="5-4-1" class="menu-item">选项1</el-menu-item>
            <el-menu-item index="5-4-2" class="menu-item">选项2</el-menu-item>
            <el-menu-item index="5-4-3" class="menu-item">选项3</el-menu-item>
          </el-submenu>
        </el-submenu> -->
        <!-- 第六栏 -->
        <el-menu-item index="/products" class="font">产品中心</el-menu-item>
        <el-menu-item index="/aboutus" class="font">关于我们</el-menu-item>
      </el-menu>
    </el-header>
    <el-main>
      <div>
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
  </div>
</template>

<script>
import logo from './assets/img/8.jpeg'
import Home from './pages/Home.vue'
import OnlineCar from './pages/OnlineCar.vue'
import Monitor from './pages/Monitor.vue'
export default {
  name: 'App',
  components: {
    Home,
    OnlineCar,
    Monitor,
  },
  data() {
    return {
      logo:logo
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log('触发')
      console.log(key, keyPath);
    }
  },
  // 3.数据挂载前
  mounted:function(){
    console.log(this)
    
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
.el-header {
  padding: 0;
  height:90px !important;
}
/* 固定导航栏 */
.el-main {
  padding: 0;
  position: absolute;
  left: 0px;
  right: 0;
  top: 90px;
  bottom: 0;
  overflow-y: scroll;
  }

/* 导航栏宽度 */
.el-menu-demo{
    height:90px;
    opacity:1;
}
.el-menu--horizontal > .el-menu-item{
    height: 90px;
    line-height: 90px;
}
.el-menu--horizontal > .el-submenu .el-submenu__title{
    height: 90px;
    line-height: 90px;
}
/* 导航栏字体 */
.font {
  font-size: 18px;
}
/* 导航栏图片 */
.menu-img {
  width: 90%;
  height: 100%;
}
/* 去除 router-link 标签样式 */
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a {
  text-decoration: none;
  color: #fff;
}
</style>
