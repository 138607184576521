<template>
  <div>
    <!-- <img src='' style='width:100%;height:100%;margin-bottom:30px;'/> -->
    <CopyrightBoard/>
  </div>
</template>

<script>
import CopyrightBoard from '../components/CopyrightBoard.vue'
export default {
    name:'AboutUs',
    components:{
        CopyrightBoard,
    }
}
</script>

<style>
    
</style>