<template>
  <div>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__backInLeft"
    leave-active-class="animate__backOutRight"	  
    >
      <CarouselBoard/>
    </transition>
    <FlashButton style="height:200px" @click.native="click"/>
    <transition
      appear
      name="animate__animated animate__bounce"
      enter-active-class="animate__backInDown"
      leave-active-class="animate__backOutDown"	  
      >
      <StretchDisplayBoard :show="show" style="padding-top:10px;"/>
    </transition>
    <IntroduceBoard style="margin-top:20px;"/>
  <!-- <div style="margin-top:50px;">
    <img src="../assets/img/WechatIMG34.jpeg" style='width:70%;height:1000px;'>
  </div> -->
    <div style="margin-top:50px;">
      
      <span style="color:rgb(57,172,224);font-size:25px;">CHINA'S INTELLIGENT TRANSPORTATION BUILDER</span>
      <p style="font-size:42px;margin:5px;">中国智慧交通建设者</p>
      <p style="text-align: center;font-size:16px;color: #333;margin-top:15px;margin-bottom:30px;">平台整合 数据联通</p>
    </div>
    <img src='../assets/img/43.png' style='width:80%;height:610px;margin-top:50px;margin-bottom:80px;'/>
    <img src='../assets/img/mowei.jpg' style='width:100%;height:800px;margin-bottom:30px;'/>
    <CopyrightBoard/>


    <!-- <AnimatedFont style='height: 200px;'/> -->
    <!-- <NeonChar text="你好，世界"/> -->
    <!-- <PicturesFlipping/> -->
    <!-- <RotatingMoon/> -->
    <!-- <HoverImgDisplayBoard/> -->

  </div>
</template>

<script>
import CarouselBoard from '../components/CarouselBoard.vue'
import FlashButton from '../components/FlashButton.vue'
import StretchDisplayBoard from '../components/StretchDisplayBoard.vue'
import IntroduceBoard from '../components/IntroduceBoard.vue'
import CopyrightBoard from '../components/CopyrightBoard.vue'
import pngzh from '../assets/img/34.png'
import pngqc from '../assets/img/36.png'
import pngdt from '../assets/img/10.png'
import pngzw from '../assets/img/32.png'


// import AnimatedFont from '../components/AnimatedFont.vue'
// import PicturesFlipping from '../components/PicturesFlipping.vue'
// import NeonChar from '../components/NeonChar.vue'
// import RotatingMoon from '../components/RotatingMoon.vue'
// import HoverImgDisplayBoard from '../components/HoverImgDisplayBoard.vue'
// import CardCarouselBoard from '../components/CardCarouselBoard.vue'
import 'animate.css'
export default {
    name:'Home',
    components:{
      CarouselBoard,
      FlashButton,
      StretchDisplayBoard,
      IntroduceBoard,
      CopyrightBoard,
      // AnimatedFont,
      // PicturesFlipping,
      // NeonChar,
      // RotatingMoon,
      // HoverImgDisplayBoard,
      // CardCarouselBoard
    },
    data(){
      return{
            show:[
              {
                  id:1,
                  src:pngzh,
                  title:'智慧出行',
                  content:'借助移动互联网、云计算、大数据、物联网等先进技术和理念，将传统交通运输业和互联网进行有效渗透与融合，打造全新出行模式。',
                  to:'/onlinecar'
              },
              {
                  id:2,
                  src:pngzw,
                  title:'政务监管',
                  content:'依靠海量的数据和精准分析增强决策的科学性，以新兴技术为依托，极大改变现有的管理结构，重新形塑政府管理的诸多方式，达到事半功倍的效果。',
                  to:'/govaffairs'
              },
              {
                  id:3,
                  src:pngdt,
                  title:'动态监控',
                  content:'面对行业应用中各种各样的需求、针对各种车型的行驶环境、行业现状、运营特性，提供一系列解决方案，服务运营商/交管部门/司机/民众。',
                  to:'/monitor'
              },
              {
                  id:4,
                  src:pngqc,
                  title:'汽车大数据',
                  content:'通过GPS、RFID、传感器、摄像头等装置设备，完成自身环境状态信息的采集（行驶里程、维修保养情况、车主的驾驶情况、行车路线、路况信息等）',
                  to:'/products'
              },
            ]
      }
    },
    methods:{
      click(e){
        // this.$router.push('/aboutus')
      }
    }
}
</script>

<style lang="less">
  .el-row {
    margin-top: 20px ;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
</style>