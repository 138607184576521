<template>
    <div class="container">
        <div class='block'>
            <img src="https://cdn.pixabay.com/photo/2021/11/20/05/15/car-6810885_1280.jpg" class="img"/>
        </div>
        <div class='block'>
            <div style='font-size:32px;color:#fff;padding-top:10px;padding-left:10px;padding-right:10px;'>
            互联网+道路运输
            </div>
            <div style='font-size:14px;color:#fff;padding:0 10px;'>
            INTERNET AND ROAD TRANSPORT
            </div>
            <div style='font-size:14px;color:#fff;padding:10px;'>
            <br/>网约车平台/顺风车平台/出租车平台<br/>扫码挪车平台/公务用车平台<br/>司导用车平台/城际直通车平台<br/>大巴票务平台/代驾平台 同城货运平台/租车平台<br/>贵州数智交通研究院为您提供智慧出行一站式解决方案
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'IntrBoard'
}
</script>

<style scoped>
.container{
  display:flex;
  height:350px;
  background:url(https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?cs=srgb&dl=pexels-luis-gomes-546819.jpg&fm=jpg);
}
.block{
  padding:40px;
  width:50%;
  text-align: left;
}
.img{
    height:270px;
    border-radius: 8px;
    width:100%;
}
</style>