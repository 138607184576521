<template>
    <div class="container">
        <div class='block1'>
            <div style='font-size:24px;color:#fff;padding-top:10px;padding-left:10px;padding-right:10px;'>
            公司介绍
            </div>
            <div style='font-size:10px;color:#fff;padding:0 10px;'>
            ABOUT US
            </div>
            <div style='font-size:10px;color:#fff;padding:10px;'>
            贵州数智交通研究院有限公司总部位于贵州省铜仁市高新区，是一家利用移动互联网、物联网、大数据和云计算技术，专注于数智交通行业管理系统及车联网相关领域精细化解决方案，集软硬件研发、生产、销售、运营于一体的国家高新技术企业。
            </div>
        </div>
        <div class='block2'>
            <img src="https://images.pexels.com/photos/12764/pexels-photo-12764.jpeg?cs=srgb&dl=pexels-creative-house-12764.jpg&fm=jpg" class="img"/>
        </div>
    </div>
</template>

<script>
export default {
    name:'IntroduceBoard'
}
</script>

<style scoped>
.container{
  display:flex;
  height:280px;
  /* background-color: #fff; */
  background:url(https://cdn.pixabay.com/photo/2016/10/11/21/43/geometric-1732847_1280.jpg);
}
.block1{
  /* background-color: blue; */
  width:50%;
  margin-top:5%;
  /* margin-bottom:5%; */
  margin-left:20%;
  margin-right:5%;
  text-align: left;
}
.block2{
  /* background-color: yellow; */
  width:50%;
  margin-top:5%;
  /* margin-bottom:5%; */
  margin-left:10%;
  margin-right:20%;
}
.img{
    height:180px;
    border-radius: 8px;
    width:100%;
}
</style>