<template>
  <div>
    <div class='container'>
        <transition
        appear
        name="animate__animated animate__bounce"
        enter-active-class="animate__fadeInLeft"
        >
            <AnimatedFont :content='packageData.content' class='left'/>
        </transition>
        <transition
        appear
        name="animate__animated animate__bounce"
        enter-active-class="animate__fadeInRight"
        >
            <img class='right' :src="packageData.src"/>
        </transition>
    </div>
    <div class='imgdisplay'>
        <div v-for='item in packageData.img' :key='item.id'>
            <transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__fadeInUpBig"
            >
                <img class='function' :src="item.url"/>
            </transition>
        </div>
    </div>
    <img :src='packageData.bottomImg' style='width:100%;height:100%;margin-bottom:30px;margin-top:60px;'/>
        <!-- <el-carousel :interval="4000" type="card" height="900px" style="width:65%;">
            <el-carousel-item v-for="item in packageData.img" :key="item.id">
                <el-image :src="item.url" class="showimg"></el-image>
            </el-carousel-item>
        </el-carousel> -->
    <CopyrightBoard/>
  </div>
</template>

<script>
import AnimatedFont from '../components/AnimatedFont.vue'
import CopyrightBoard from '../components/CopyrightBoard.vue'
import pngzhcxgnmb from '../assets/img/35.png'
import pngbyyc from '../assets/img/18.png'
import pngcjzx from '../assets/img/19.png'
import pngczc from '../assets/img/9.png'
import pngdj from '../assets/img/7.png'
import pngdzky from '../assets/img/20.png'
import pngkc from '../assets/img/21.png'
import pngsfc from '../assets/img/42.png'
import pngzc from '../assets/img/zhuanchegongneng.png'
import pngpphy from '../assets/img/39.png'
import pngdcbgd from '../assets/img/30.png'
import pngddsgd from '../assets/img/27.png'
import pngdbsgd from '../assets/img/24.png'
import pngpphygd from '../assets/img/41.png'
export default {
    name:'AppIntr',
    components:{
        AnimatedFont,
        CopyrightBoard
    },
    props:['type'],
    data(){
        return{
            packageData:{
                src:pngzhcxgnmb,
                content:[
                    
                ],
                img:[

                ],
                bottomImg:''
            }
        }
    },
    beforeMount(){
        console.log(this.$route.query.type);
        if(this.$route.query.type==1){
            this.$data.packageData.content = [
                {
                    id:1,
                    text:'DA CHE BEI'
                },
                {
                    id:2,
                    text:'搭车呗'
                },
            ]
            this.$data.packageData.img = [
                {
                    id:1,
                    url:pngcjzx
                },
                {
                    id:2,
                    url:pngkc
                },
                {
                    id:3,
                    url:pngdj
                },
                {
                    id:4,
                    url:pngbyyc
                },
                {
                    id:5,
                    url:pngzc
                },
            ]
            this.$data.packageData.bottomImg = pngdcbgd
        }
        if(this.$route.query.type==2){
            this.$data.packageData.content = [
                {
                    id:1,
                    text:'DA DI SHI'
                },
                {
                    id:2,
                    text:'搭的士'
                },
            ]
            this.$data.packageData.img = [
                {
                    id:1,
                    url:pngdj
                },
                {
                    id:2,
                    url:pngbyyc
                },
                {
                    id:3,
                    url:pngczc
                },
                {
                    id:4,
                    url:pngzc
                },
            ]
            this.$data.packageData.bottomImg = pngddsgd
        }
        if(this.$route.query.type==3){
            this.$data.packageData.content = [
                {
                    id:1,
                    text:'DA BA SHI'
                },
                {
                    id:2,
                    text:'搭巴士'
                },
            ]
            this.$data.packageData.img = [
                {
                    id:1,
                    url:pngdzky
                },
                {
                    id:2,
                    url:pngcjzx
                },
                {
                    id:3,
                    url:pngsfc
                },
            ]
            this.$data.packageData.bottomImg = pngdbsgd
        }
        if(this.$route.query.type==4){
            this.$data.packageData.content = [
                {
                    id:1,
                    text:'PAO PAO HUO YUN'
                },
                {
                    id:2,
                    text:'跑跑货运'
                },
            ]
            this.$data.packageData.img = [
                {
                    id:1,
                    url:pngpphy
                },
            ]
            this.$data.packageData.bottomImg = pngpphygd
        }
    },
}
</script>

<style scoped>
.container {
    justify-content: center;
    align-items: center;
    text-align: center;
    display:flex;
    height:400px;
    padding:20px;
}
.left {
    width:50%;
    height: 50%;
}
.right {
    width:50%;
    height: 400px;
}
.imgdisplay {
    display: flex;
    padding:20px;
}
.function{
    width: 100%;
    height: 100%px;
}

/* .showimg{
    height:900px;
    width: 100%
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}
  
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
} */
</style>