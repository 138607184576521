<template>
  <div>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__bounceInDown"
    >
      <div style="padding-top:45px;margin:45px;">
        <span style="color:rgb(57,172,224);font-size:25px;">OMNIDIRECTIONAL SMART TRAVEL SCHEME</span>
        <p style="font-size:42px;margin:5px;">全方位智慧出行方案</p>
        <!-- <p style="text-align: center;font-size:16px;color: #333;">平台整合 数据联通</p> -->
      </div>
    </transition>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__fadeInUpBig"
    >
      <img src='../assets/img/37.jpg' style='width:90%;height:310px;margin-bottom:60px;'/>
    </transition>
    <StretchDisplayBoard :show='show' style="margin-bottom:10px;"/>
    <CarCarouselBoard :context='context'/>
    <!-- <NeonChar style='height:200px;' text="你好，世界"/> -->
    <IntrBoard/>
    <CopyrightBoard/>
  </div>
</template>

<script>
import 'animate.css'
import StretchDisplayBoard from '../components/StretchDisplayBoard.vue'
import CarCarouselBoard from '../components/CardCarouselBoard.vue'
import NeonChar from '../components/NeonChar.vue'
import CopyrightBoard from '../components/CopyrightBoard.vue'
import IntrBoard from '../components/IntrBoard.vue'
import png1 from '../assets/img/29.png'
import png2 from '../assets/img/23.png'
import png3 from '../assets/img/40.png'
import png4 from '../assets/img/26.png'
import png5 from '../assets/img/28.png'
import png6 from '../assets/img/25.png'
import png7 from '../assets/img/22.png'
import png8 from '../assets/img/38.png'
export default {
    name:'OnlineCar',
    components:{
      CarCarouselBoard,
      StretchDisplayBoard,
      NeonChar,
      CopyrightBoard,
      IntrBoard
    },
    data(){
      return{
        context:[
            {
                id:1,
                url:png1,
            },
            {
                id:2,
                url:png2
            },
            {
                id:3,
                url:png3
            },
            {
                id:4,
                url:png4
            },
        ],
        show:[
          {
              id:1,
              src:png5,
              title:'灵活运营',
              content:'支持自营、联合经营、分包经营的网约车平台。通过平台独特的“联盟经营账号”联合地方多家关联企业突破独家推广的困境，合力推广、携手共赢。',
              to:'/appintr'
          },
          {
              id:2,
              src:png6,
              title:'专注的士',
              content:'专业出租车出行平台，一车一码，定向派单，微信扫码，一键登录。自动匹配，急速同行，高额返现，快乐出行。',
              to:'/appintr'
          },
          {
              id:3,
              src:png7,
              title:'巴士定制',
              content:'便捷、平价、安全、快速、高效的客运交通平台，采用动态监控技术筑起客运行程的安全保障，借助信息化技术打造线上客运中心。',
              to:'/appintr'
          },
          {
              id:4,
              src:png8,
              title:'货运无忧',
              content:'自主研发、孵化的智能货运系统，通过共享模式整合社会运力资源，实现多种车型的即时智能调度，为个人、商户及企业提供高效的同城货运解决方案。',
              to:'/appintr'
          },
        ]
      }
    }
}
</script>

<style>

</style>