<template>
  <el-carousel :interval="5000" height="410px" arrow="always" @wheel.native="wheelChange" >
    <el-carousel-item v-for="item in showImg" :key="item.id">
      <el-image class="showimg" :src="item.url"></el-image>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import jpg1 from '../assets/img/shouyelunbo1.jpg'
// import jpg2 from '../assets/img/首页轮播2.jpg'
export default {
    name:'CarouselBoard',
    data(){
      return{
        showImg:[
          {id:1,url:jpg1},
          {id:2,url:jpg1},
          {id:3,url:jpg1},
          {id:4,url:jpg1},
        ]
      }
    },
    methods:{
      wheelChange(e){
        // console.log(e.wheelDelta)
        // if(e.wheelDelta<-6){
        //   this.$router.push('/onlinecar')
        // }
      }
    }
}
</script>

<style scoped>
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .showimg{
    height:410px;
    width: 100%
  }

</style>