<template>
    <div class="container">
        <button class="btn btn-primary btn-ghost btn-shine">
            数智交通，未来已来
        </button>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* background: #fff; */
  /* background: #1A1E23; */
  background: #fff;
}
.btn {
  --hue: 190;
  position: relative;
  padding: 1rem 3rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  background-color: hsl(var(--hue), 100%, 41%);
  border: 1px solid hsl(var(--hue), 100%, 41%);
  outline: transparent;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  transition: 0.25s;
}
.btn:hover {
  background: hsl(var(--hue), 100%, 31%);
}
.btn-primary {
  --hue: 187;
}
.btn-ghost {
  color: hsl(var(--hue), 100%, 41%);
  background-color: transparent;
  border-color: hsl(var(--hue), 100%, 41%);
}
.btn-shine {
  /* hover 前字体颜色; */
  color: rgb(30, 185, 208);
}
.btn-ghost:hover {
  /* hover 后字体颜色; */
  color: rgb(42, 146, 197);
}
.btn-shine::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, hsla(var(--hue), 100%, 41%, 0.5), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}
.btn-shine:hover {
  background: transparent;
  box-shadow: 0 0 20px 10px hsla(var(--hue), 100%, 41%, 0.5);
}
.btn-shine:hover::before {
  transform: translateX(100%);
}
</style>