<template>
    <div style="display:flex;">
        <ImageHover :context="show[0]"/>
        <ImageHover :context="show[1]"/>
        <ImageHover :context="show[2]"/>
    </div>
</template>

<script>
import ImageHover from '../components/ImageHover.vue'
export default {
    name:'HoverImgDisplayBoard',
    components:{
        ImageHover
    },
    data(){
        return{
            show:[
                {
                    src:'http://www.svan.cc/img/%E6%B8%A3%E5%9C%9F%E8%BD%A6%E8%A1%8C%E4%B8%9A%E7%9B%91%E7%AE%A1%E7%B3%BB%E7%BB%9F.jpg',
                    text:'非现场全局布控、一体化指挥调度、一线高效执法、全流程网上办案、全方位督查考核于一体的交通综合执法平台，推动交通执法由“大海捞针”式的“粗放型”执法模式向“定点清除”式“精准打击”执法模式转型，实现执法效能“质”和“量”的双提升'
                },
                {
                    src:'http://www.svan.cc/img/%E5%85%AC%E8%B7%AF%E5%85%BB%E6%8A%A4%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F.jpg',
                    text:'通过视频AI识别及特征分析比对，实现违法识别及预警;接收执法预警信息，支持多方式融合通讯及可视化指挥'
                },
                {
                    src:'http://www.svan.cc/img/%E5%BA%94%E6%80%A5%E6%8C%87%E6%8C%A5%E8%B0%83%E5%BA%A6%E7%B3%BB%E7%BB%9F.jpg',
                    text:'通过移动执法终端支撑一线高效巡查及案件办理,随时随地线上办案，实现交通案件全流程网上办理,对案件抽查，对重大案件督办跟进，支撑执法评议考核'
                },
            ]
        }
    }
}
</script>

<style scoped>
    .container{
        display:flex;
        justify-content:
        space-around;
        height:280px;
    }
</style>