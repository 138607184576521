import { render, staticRenderFns } from "./FlashButton.vue?vue&type=template&id=35588854&scoped=true&"
import script from "./FlashButton.vue?vue&type=script&lang=js&"
export * from "./FlashButton.vue?vue&type=script&lang=js&"
import style0 from "./FlashButton.vue?vue&type=style&index=0&id=35588854&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35588854",
  null
  
)

export default component.exports