<template>
  <div>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__bounceInDown"
    >
      <div style="padding-top:45px;margin-bottom:120px;">
        <span style="color:rgb(57,172,224);font-size:25px;">GUI ZHOU SHU ZHI JIAO TONG YAN JIU YUAN</span>
        <p style="font-size:42px;margin:5px;">助力监管，为政务赋能</p>
        <!-- <p style="text-align: center;font-size:16px;color: #333;margin:20px 0;">智慧交通，未来已来</p> -->
      </div>
    </transition>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__fadeInUpBig"
    >
      <HoverImgDisplayBoard style="margin-bottom:40px;"/>
    </transition>
    <img src='../assets/img/31.jpg' style='width:100%;height:800px;margin-top:100px;'/>
    <CopyrightBoard/>
  </div>
</template>

<script>
import HoverImgDisplayBoard from '../components/HoverImgDisplayBoard.vue'
import CopyrightBoard from '../components/CopyrightBoard.vue'
export default {
    name:'GovAffairs',
    components:{
      HoverImgDisplayBoard,
      CopyrightBoard
    }
}
</script>

<style>

</style>