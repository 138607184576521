<template>
  <div>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__bounceInDown"
    >
        <div style="padding-top:45px;">
            <span style="color:rgb(57,172,224);font-size:25px;">DATA SHARING, INTERCONNECTION OF ALL THINGS</span>
            <p style="font-size:42px;margin:5px;">数据共享，万物互联</p>
            <!-- <p style="text-align: center;font-size:16px;color: #333;">平台整合 数据联通</p> -->
        </div>
    </transition>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__fadeInUpBig"
    >
        <div style='padding-top:60px;padding-left:60px;padding-right:60px;'>
            <ProductDisplay :content="content"/>
        </div>
    </transition>
    <el-button size="mini" class='morebutton' @click.native='clickButton'>更多产品详情 >></el-button>
    <img src='../assets/img/6.jpg' style='width:100%;height:760px;margin-top:40px;'/>
    <CopyrightBoard/>
  </div>
</template>

<script>
import CopyrightBoard from '../components/CopyrightBoard.vue'
import ProductDisplay from '../components/ProductDisplay.vue'
import png1 from '../assets/img/chanping1.png'
import png2 from '../assets/img/chanping2.png'
import png3 from '../assets/img/chanping3.png'
import png4 from '../assets/img/chanping4.png'
// import png5 from '../assets/img/产品5.png'
// import png6 from '../assets/img/产品6.png'
export default {
    name:'Products',
    components:{
        CopyrightBoard,
        ProductDisplay
    },
    data(){
        return{
            content:[
                {
                    id:1,
                    url:png1,
                    text:"小芾1智能云镜：ANDROID5.1，更快、更稳定；支持百度地图、高德导航（语音控制）；一键拍照，录取前后摄像头各 10S 短视频，支持腾讯在线声控"
                },
                {
                    id:2,
                    url:png2,
                    text:"小芾4G执法记录仪：高清音视频传输功能，支持 1080P/720PVGA/分辨率;支持双码流高清实时传输;双向语音对讲，通过设备的麦克可与后台监控中心实现P网络语音通话"
                },
                {
                    id:3,
                    url:png4,
                    text:"小芾4行车记录仪：外置GPS+北斗双模高灵敏陶瓷天线；腾讯电子狗实时预警路况播报；3轴G-sensor传感器，支持循环录像；内置MIC-phone降噪处理；5点式电容式触摸屏"
                },
                {
                    id:4,
                    url:png3,
                    text:"小芾DMS7终端一体机：车道偏离预警、车距保持预警、前方车辆碰撞预警、前车起步提醒、ADAS自标定；驾驶员疲劳预警、驾驶员注意力集中度预警、驾驶员抽烟提醒、驾驶员接驳电话提醒、驾驶员异常预警"
                },
            ]
        }
    },
    methods:{
        clickButton(){
            this.$router.push('/productsdetail')
        }
    }
}
</script>

<style scoped>
.morebutton {
    margin-bottom:40px;
}
</style>