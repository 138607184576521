// 1. 引入路由
import VueRouter from 'vue-router'
// 2. 引入组件
import Home from '../pages/Home.vue'
import OnlineCar from '../pages/OnlineCar.vue'
import Monitor from '../pages/Monitor.vue'
import GovAffairs from '../pages/GovAffairs.vue'
import AboutUs from '../pages/AboutUs.vue'
import Products from '../pages/Products.vue'
import AppIntr from '../pages/AppIntr.vue'
import ProductsDetail from '../pages/ProductsDetail.vue'
// 3. 创建路由器并暴露
export default new VueRouter({
	routes:[
		{	// 设置默认路由页面
			path: '/',
			redirect: 'home'
		},
		{
			name:'home',
			path:'/home',
			component:Home,
		},
		{
			name:'onlinecar',
			path:'/onlinecar',
			component:OnlineCar,
		},
		{
			name:'govaffairs',
			path:'/govaffairs',
			component:GovAffairs,
		},
		{
			name:'monitor',
			path:'/monitor',
			component:Monitor,
		},
		{
			name:'products',
			path:'/products',
			component:Products,
		},
		{
			name:'aboutus',
			path:'/aboutus',
			component:AboutUs,
		},
		{
			name:'appintr',
			path:'/appintr',
			component:AppIntr,
		},
		{
			name:'productsdetail',
			path:'/productsdetail',
			component:ProductsDetail,
		}
	]
})