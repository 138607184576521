// 与 NeonChar 和 RotatingMoon 不兼容
<template>
    <div class='container'>
        <div class="scene">
            <div class="card" v-for="item in content" :key="item.id">
                <div class="card__face card__face--front">
                    <img :src="item.before"/>
                </div>
                <div class="card__face card__face--back">
                    <img :src="item.after" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pngz1 from '../assets/img/15.png'
import pngz2 from '../assets/img/16.png'
import pngz3 from '../assets/img/17.png'
import pngf1 from '../assets/img/11.png'
import pngf2 from '../assets/img/12.png'
import pngf3 from '../assets/img/13.png'
export default {
  name:'PicturesFlipping',
  data(){
    return{
      content:[
        {
          id:1,
          before:pngz1,
          after:pngf1
        },
        {
          id:2,
          before:pngz2,
          after:pngf2
        },
        {
          id:3,
          before:pngz3,
          after:pngf3
        },
      ]
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  // background: black;
  background: #fff;
}
.scene {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  perspective: 800px;

  .card {
    position: relative;
    width: 300px;
    height: 210px;
    color: white;
    cursor: pointer;
    transition: 1s ease-in-out;
    transform-style: preserve-3d;

    &:hover {
      transform: rotateY(0.5turn);
    }

    .card__face {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: 1s ease-in-out;
      -webkit-box-reflect: below 0
        linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.4));

      img {
        width: 300px;
        height: 200px;
        object-fit: cover;
      }

      &--back {
        transform: rotateY(0.5turn);
      }
    }
  }
}
</style>