<template>
    <div style="text-align:left;padding:50px;background-color:black;margin-top:50px;">
      <p class="smalltext">总部：贵州省铜仁市碧江区川硐高新区电商园一期A8栋2楼</p>
      <p class="smalltext">电话：18385910888</p>
      <p class="smalltext">微信：18385910888</p>
      <p class="smalltext">邮箱：qq18385910888@163.com</p>
      <el-divider></el-divider>
      <p class="bigtext">
          Copyright ©2022 www.szjtyjy.com 贵州数智交通研究院有限公司 All Rights Reserved.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://beian.miit.gov.cn/#/Integrated/index">黔ICP备2022003582号</a>
      </p>
    </div>
</template>

<script>
export default {
    name:'CopyrightBoard'
}
</script>

<style scoped>
.container{
    
}
.smalltext{
    font-size:16px;margin:5px;color:#fff;
}
.bigtext{
    font-size:14px;color: #fff;margin:5px;margin-bottom:0px;
}
.el-divider--horizontal {
    margin: 20px 0;
}
a:hover{
    text-decoration: none; 
    border-bottom: 1px solid #fff;
}
</style>