<template>
  <div>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__bounceInDown"
    >
      <div style="padding-top:45px;">
        <span style="color:rgb(57,172,224);font-size:25px;">DYNAMIC MONITORING, SAFE AND CAREFREE</span>
        <p style="font-size:42px;margin:5px;">动态监控，安全无忧</p>
        <!-- <p style="text-align: center;font-size:16px;color: #333;">平台整合 数据联通</p> -->
      </div>
    </transition>
    <transition
    appear
    name="animate__animated animate__bounce"
    enter-active-class="animate__fadeInUpBig"
    >
      <PicturesFlipping/>
    </transition>
    <img src='../assets/img/14.jpg' style='width:100%;height:800px;margin-top:100px;'/>
    <CopyrightBoard/>
  </div>
</template>

<script>
import PicturesFlipping from '../components/PicturesFlipping.vue'
import CopyrightBoard from '../components/CopyrightBoard.vue'
export default {
    name:'Monitor',
    components:{
      PicturesFlipping,
      CopyrightBoard
    }
}
</script>

<style>

</style>