<template>
        <figure class="imghvr-shutter-out-horiz">
            <img :src="context.src">
            <figcaption style="text-align: left;">
                {{context.text}}
            </figcaption>
        </figure>
</template>

<script>
export default {
    name:'ImageHover',
    props:['context'],
}
</script>

<style>
    [class^='imghvr-'],
    [class*='imghvr-'] {
        background-color: #D14233;
    }
</style>