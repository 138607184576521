import Vue from 'vue'
import App from './App.vue'
import router from './router' // 引入路由器
import VueRouter from 'vue-router' // 引入路由器插件
// 按需引入 element-ui 组件
import { Button,Card,Carousel,CarouselItem,Col,Container,Divider,Footer,Header,Image,Main,Menu,MenuItem,Row,Submenu} from 'element-ui';
// import less from 'less'
import '../node_modules/imagehover.css/css/imagehover.css'
// Vue.use(less)
// 使用路由插件
Vue.use(VueRouter)
// 按需使用 element-ui 组件
Vue.use(Button)
Vue.use(Card)
Vue.use(CarouselItem)
Vue.use(Carousel)
Vue.use(Col)
Vue.use(Container)
Vue.use(Divider)
Vue.use(Footer)
Vue.use(Header)
Vue.use(Image)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Row)
Vue.use(Submenu)



Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
