<template>
<div class="container">
    <ul>
        <li><a v-for='item in content' :key='item.id' href="#">{{item.text}}</a></li>
    </ul>
</div>
</template>

<script>
  export default {
    name:'AnimatedFont',
    props:['content']
  }
</script>

<style scoped lang="less">
.container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  // background: #1A1E23;
}
ul {
  display: flex;
  flex-direction: column;
  align-items: start;
  list-style-type: none;

  li {
    padding: 6px 0;

    a {
      --fill-color: #198CE6;
      position: relative;
      display: block;
      padding: 4px 0;
      font: 700 3rem Raleway, sans-serif;
      text-decoration: none;
      text-transform: uppercase;
      -webkit-text-stroke: 2px var(--fill-color);
      background: linear-gradient(var(--fill-color) 0 100%) left / 0 no-repeat;
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      transition: 0.5s linear;

      &:hover {
        background-size: 100%;
      }
    }
  }
}
</style>