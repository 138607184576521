<template>
  <div>
    <div style="padding:40px">
      <transition
          appear
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
      >
        <img src='../assets/img/chanpingjieshao1.png' style='width:100%;height:100%;margin-bottom:30px;'/>
      </transition>
        <img src='../assets/img/1.png' style='width:100%;height:100%;margin-bottom:30px;'/>
        <img src='../assets/img/2.png' style='width:100%;height:100%;margin-bottom:30px;'/>
        <img src='../assets/img/3.png' style='width:100%;height:100%;margin-bottom:30px;'/>
        <img src='../assets/img/4.png' style='width:100%;height:100%;margin-bottom:30px;'/>
        <img src='../assets/img/5.png' style='width:100%;height:100%;margin-bottom:30px;'/>
    </div>
    <CopyrightBoard/>
  </div>
</template>

<script>
import CopyrightBoard from '../components/CopyrightBoard.vue'
export default {
    name:'ProductsDetail',
    components:{
      CopyrightBoard
    }
}
</script>

<style>

</style>